export default {
  components: {
    DropdownItem: () => import('~/components/commom/dropdown-item/index')
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String
    },
    options: {
      type: Array
    },
    borders: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      isOptionsVisible: false
    }
  },
  computed: {
    dropdownClasses() {
      return {
        'dropdown--no-borders': !this.borders
      }
    },
    itemsClasses() {
      return {
        'dropdown__items--visible': this.isOptionsVisible,
        'dropdown__items--top': this.position === 'top'
      }
    },
    svgArrowClasses() {
      return {
        'dropdown__arrow--reverse': this.isOptionsVisible
      }
    },
    hasValue() {
      return this.value !== null
    },
    activeValue() {
      if (!this.hasValue) {
        return null
      }

      return this.options.filter((option) => {
        return option.value === this.value
      })[0]
    }
  },
  methods: {
    handleClickOnDrop() {
      this.isOptionsVisible = !this.isOptionsVisible
      this.$emit('open')
    },
    handleUpdate(event) {
      this.isOptionsVisible = false
      this.$emit('input', event)
    }
  }
}
